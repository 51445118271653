<template>
  <main class="kb-main" id="kb-kb-educourse-schedule" @click="closeToggles(toggles)">
    <LxpMobileHeader v-if="isMobile" :title="`${isSearch ? '' : '연간연수일정'.concat(' ',paging.totalCount,'건')}`">
      <template v-slot:left>
        <template v-if="isSearch">
          <div class="util util-back">
            <a href="javascript:" class="util-actions util-actions-back" @click="clickBack">
              <i class="icon-history-back"></i>
            </a>
          </div>
          <!--          <div class="util util-search-input">-->
          <!--            <input type="text" placeholder="연수신청 검색" v-model="search.crseNm" @keyup.enter="toggleSearch">-->
          <!--          </div>-->
        </template>
        <template v-else>
          <div class="util util-more">
            <a href="javascript:" class="util-actions util-actions-search" @click="headerToggle">
              <i class="icon-more"></i>
            </a>
            <div class="dropdown" :class="{'is-active': hToggle}" data-offset="header-left">
              <div class="dropdown-target">
                <div class="dropdown-box">
                  <ul class="dropdown-option-list">
                    <li class="dropdown-option-item">
<!--                      <file-down-link :file-key="guideFileKey" :add-link-class="['dropdown-option-link']" :add-text-class="['dropdown-option-text']" />-->
                    </li>
                    <!--                    <li class="dropdown-option-item"><a href="javascript:" class="dropdown-option-link is-active"><span-->
                    <!--                        class="dropdown-option-text">직무인증제 안내<i class="icon-download"></i></span></a></li>-->
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </template>
      </template>
      <template v-slot:right>
        <div class="util util-search">
<!--          <a href="javascript:" class="util-actions util-actions-search" @click="toggleSearch">-->
<!--            <i class="icon-search"></i>-->
<!--          </a>-->
        </div>
      </template>
    </LxpMobileHeader>
    <lxp-main-header
        v-if="!isMobile"
        :show-title="true"
        :show-breadcrumb="true"
        title="연수신청">
      <template v-slot:inner>
        <div class="header-column">
          <div class="download">
<!--            <file-down-link :file-key="guideFileKey" :add-text-class="['download-link']" />-->
          </div>
        </div>
      </template>
      <template v-slot:action>
        <div class="d-flex justify-content-center align-items-center" style="margin-top: var(--kb-spacer-4)">
          <div class="header-tab">
            <nav class="kb-nav-round-tabs">
              <ul class="kb-nav-list">
                <li class="kb-nav-item">
                  <router-link :to="{name: 'ApplyAnnualMain'}" class="kb-nav-link is-active">
                    <span class="kb-nav-text">집합</span>
                  </router-link>
                </li>
                <li class="kb-nav-item">
                  <router-link :to="{name: 'ApplyAnnual'}" class="kb-nav-link">
                    <span class="kb-nav-text">통신·사이버</span>
                  </router-link>
                </li>
              </ul>
            </nav>
          </div>
          <div class="header-actions" style="margin-top: 0px; margin-left:  var(--kb-spacer-3)">
            <SortButtonSelect
                v-model="crseDate.year"
                v-model:toggle="toggles.year"
                title="년도"
                :options="yearOptions"
                sequence-key="value"
                name-key="display"
                :is-num="true"
                button-class="kb-btn kb-btn-outline kb-btn-actions"
                @update:toggle="closeToggles(toggles, 'year')"
                @selected="pagingFunc()"
            />
          </div>
        </div>
      </template>
    </lxp-main-header>

    <div class="main-content main-component">
      <div class="educourse-list-container">
        <ApplyCalendar
            v-if="initEffected"
            :items="items"
            :learns="learns"
            :crseYear="crseDate.year+''"
        />
      </div>
    </div>

  </main>
</template>

<script>

import LxpMainHeader from '@/components/common/LxpMainHeader';
import {computed, reactive, ref, watch} from 'vue';
import {useStore} from 'vuex';
import {useRoute} from 'vue-router';
import {closeToggles, getDcdOptions, getListFunc, getValueByQuery, setFilterByQuery,} from '@/assets/js/ui.init';
import {ACT_GET_GRP_CRSE_DIST_LIST, ACT_GET_REG_APPLY_ANNUAL} from '@/store/modules/course/course';
import navigationUtils from '@/assets/js/navigationUtils';
import LxpMobileHeader from '@/views/layout/lxp/header/mobile/LxpMobileHeader';
import ApplyCalendar from "@/components/apply/ApplyCalendar.vue";
import SortButtonSelect from "@/components/common/SortButtonSelect.vue";
import {useAlert} from "@/assets/js/modules/common/alert";
// import FileDownLink from "@/components/common/FileDownLink";

export default {
  name: 'ApplyAnnualMain',
  components: {
    LxpMainHeader,
    LxpMobileHeader,
    SortButtonSelect,
    ApplyCalendar,
    // FileDownLink,
  },
  setup(){
    const isMobile = navigationUtils.any();
    const calendarModal = ref(false);
    const {showLoading, hideLoading} = useAlert();

    const currentDate = new Date();
    const store = useStore();
    const route = useRoute();
    const hToggle = ref(false);
    const isSearch = ref(false);
    const searchCnt = ref(0);
    const initEffected = ref(false);

    const items = ref([]);
    const learns = ref([
      // {distCrseSn: 1000, bgngDt: 1677337200000, endDt: 1677682800000, distCrseNm : '차수이름1'},  //02-26 ~ 03-02
      // {distCrseSn: 2000, bgngDt: 1677423600000, endDt: 1678201200000, distCrseNm : '차수이름2'},  //02-27 ~ 03-08
      // {distCrseSn: 3000, bgngDt: 1677682800000, endDt: 1678201200000, distCrseNm : '차수이름3'},  //03-02 ~ 03-08
    ]);

    const search = reactive({
      crseNm: '',
      orgnzCdDcd: '',
      mediaCateCd: '',
    });

    const resetParams = () => {
      search.crseNm = '';
      search.orgnzCdDcd = '';
      search.mediaCateCd = '';
    };

    const crseDate = reactive({
      year: getValueByQuery(route.query, 'crseYear', true, currentDate.getFullYear()),
    });

    const yearOptions = (() => {
      const options = [];
      for(let i = currentDate.getFullYear() + 1; i >= currentDate.getFullYear() - 1 ; i--){
        options.push({value: i, display: `${i}년`});
      }
      return options;
    })();

    const toggles = reactive({
      orgnzCdDcd: false,
      mediaCateCd: false,
      year: false
    });

    const orgnzCdOptions = computed(() => getDcdOptions('2016'));
    const cateOptions = computed(() => store.state.code.cates);

    setFilterByQuery(search, route.query);

    const mutItems = () => {
      items.value = items.value.map(x => ({...x, isLike: (x.myLikeCnt > 0)}));
    }

    const headerToggle = () => {
      if (hToggle.value) {
        hToggle.value = false;
      } else {
        hToggle.value = true;
      }
    };

    const clickBack = () => {
      isSearch.value = false;
      hToggle.value = false;
      resetParams();
      // if(Object.keys(route.query).length === 0) getCourseApplyAnnual();
      // else pushQuery({...search});
    };

    watch(() => route.query, () => {
      if (route.name === 'ApplyAnnualMain') {
        setFilterByQuery(search, route.query);
      }
    });

    const pagingFunc = () => {
      showLoading();
      initEffected.value = false;
      getListFunc(`course/${ACT_GET_GRP_CRSE_DIST_LIST}`, {crseYear: crseDate.year}, learns, null, () => {});
      getListFunc(`course/${ACT_GET_REG_APPLY_ANNUAL}`, {...search, crseYear: crseDate.year}, items, null, mutItems);
      setTimeout(() => {initEffected.value = true, hideLoading()}, 1000);
    }

    showLoading();
    getListFunc(`course/${ACT_GET_GRP_CRSE_DIST_LIST}`, {crseYear: crseDate.year}, learns, null, () => {});
    getListFunc(`course/${ACT_GET_REG_APPLY_ANNUAL}`, {...search, crseYear: crseDate.year}, items, null, mutItems);

    setTimeout(() => {initEffected.value = true, hideLoading()}, 1000);

    return {
      calendarModal,
      items,
      learns,
      search,
      crseDate,
      initEffected,
      toggles,
      yearOptions,
      orgnzCdOptions,
      cateOptions,
      closeToggles,
      isMobile,
      hToggle,
      headerToggle,
      isSearch,
      clickBack,
      searchCnt,
      pagingFunc,
      guideFileKey: 'AnnualCourseList',
    }
  }
}
</script>
